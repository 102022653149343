import React, { forwardRef, useMemo } from "react";
import { StyleSheet, Text as RNText, Platform } from "react-native";

import { body, title, caption, button } from "../../styles/text";
import { useTheme } from "../../utils/theme";
import { useTextScaling } from "../../context/TextScaling";

import type { ThemeFont } from "../../utils/theme";
import type {
  StyleProp,
  TextStyle,
  TextProps as RNTextProps,
  ViewStyle,
} from "react-native";
import type { ReactNode, Ref } from "react";
import type { TestProps } from "../../types";

export type TextType =
  | "body"
  | "bodySmall"
  | "bodyLarge"
  | "bodySemibold"
  | "bodySmallSemibold"
  | "bodyLargeSemibold"
  | "title"
  | "title2"
  | "title3"
  | "title4"
  | "title5"
  | "title6"
  | "titleBold"
  | "title2Bold"
  | "title3Bold"
  | "title4Bold"
  | "title5Bold"
  | "title6Bold"
  | "caption"
  | "captionSmall"
  | "captionLarge"
  | "captionSemibold"
  | "captionSmallSemibold"
  | "captionLargeSemibold"
  | "button";

export interface TextProps extends RNTextProps, TestProps {
  type?: TextType;
  style?: StyleProp<TextStyle>;
  children?: ReactNode;
}

export interface useTextPropsOpts
  extends Pick<RNTextProps, "accessibilityRole"> {
  type?: TextType;
}

export function useTextProps({
  accessibilityRole,
  type = "body",
}: useTextPropsOpts) {
  const { fonts } = useTheme();

  const fontWeight: ThemeFont = useMemo(() => {
    if (type.includes("Bold")) {
      return "bold";
    }

    if (type.includes("Semibold")) {
      return "semibold";
    }

    // backwards support caption being semibold
    // this does mean that if you want a regular caption you have to pass the "font" prop
    if (type.includes("caption")) {
      return "semibold";
    }

    if (type.includes("title")) {
      return "semibold";
    }

    if (type.includes("button")) {
      return "semibold";
    }

    return "regular";
  }, [type]);

  // switch between font weights based on text type
  const font = useMemo(() => fonts[fontWeight], [fontWeight, fonts]);
  const isHeading = useMemo(
    () =>
      ["title", "title2", "title3", "title4", "title5", "title6"].includes(
        type
      ),
    [type]
  );

  const ariaLevel = useMemo(() => {
    if (isHeading) {
      switch (type) {
        case "title":
          return "1";
        case "title2":
          return "2";
        case "title3":
          return "3";
        case "title4":
          return "4";
        case "title5":
          return "5";
        case "title6":
          return "6";
        default:
          return undefined;
      }
    }

    return undefined;
  }, [isHeading, type]);

  return {
    accessibilityRole: accessibilityRole ?? (isHeading ? "header" : "text"),
    accessibilityLevel: isHeading ? ariaLevel : null,
    fontStyles: [font, textStyles[type]],
  };
}

export const Text = forwardRef(function Text(
  {
    children,
    style,
    type,
    accessibilityRole,
    testID,
    numberOfLines,
    ...rest
  }: TextProps,
  ref: Ref<RNText>
) {
  const { colors } = useTheme();
  const { allowFontScaling, maxFontSizeMultiplier } = useTextScaling();
  const { fontStyles, ...props } = useTextProps({ type, accessibilityRole });
  const safariStyles = useMemo(() => {
    if (Platform.OS !== "web") return undefined;

    const ua = navigator?.userAgent?.toLowerCase();
    if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) {
      if (numberOfLines === 1) {
        return {
          whiteSpace: "nowrap",
        } as ViewStyle;
      }

      return {
        WebkitLineClamp: numberOfLines,
      } as ViewStyle;
    }

    return undefined;
  }, [numberOfLines]);

  return (
    <RNText
      ref={ref}
      testID={testID}
      numberOfLines={numberOfLines}
      allowFontScaling={allowFontScaling}
      maxFontSizeMultiplier={maxFontSizeMultiplier}
      style={[
        textStyles.root,
        safariStyles,
        {
          color: colors.textPrimary,
        },
        fontStyles,
        style,
      ]}
      {...props}
      {...rest}
    >
      {children}
    </RNText>
  );
});

/* eslint-disable react-native/no-unused-styles */
export const textStyles = StyleSheet.create({
  root: body.normal,
  title: title.one,
  title2: title.two,
  title3: title.three,
  title4: title.four,
  title5: title.five,
  title6: title.six,
  titleBold: title.one,
  title2Bold: title.two,
  title3Bold: title.three,
  title4Bold: title.four,
  title5Bold: title.five,
  title6Bold: title.six,
  bodyLarge: body.large,
  bodySmall: body.small,
  body: {
    // default, see root
  },
  bodyLargeSemibold: body.large,
  bodySmallSemibold: body.small,
  bodySemibold: body.normal,
  captionLarge: caption.large,
  captionSmall: caption.small,
  caption: caption.normal,
  captionLargeSemibold: caption.large,
  captionSmallSemibold: caption.small,
  captionSemibold: caption.normal,
  button: button.normal,
});
/* eslint-enable react-native/no-unused-styles */
