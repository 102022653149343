import React, { forwardRef, useMemo } from "react";

import { Text } from "../Text";
import { useTheme } from "../../utils/theme";

import type { Ref } from "react";
import type { Text as RNText } from "react-native";
import type { ThemeColor, ThemeColorAlias, ThemeFont } from "@smartrent/tokens";
import type { TextProps } from "../Text";

export type TextThemeColor = ThemeColor | ThemeColorAlias;

export interface TypographyProps extends TextProps {
  color?: TextThemeColor;
  font?: ThemeFont;
  focusable?: boolean;
}

/**
 * A text component with preset fonts, sizes, and colors.
 *
 * example: https://smartrent-ui.com/components/general/typography
 */
export const Typography = forwardRef(function Typography(
  { color, font, style, ...rest }: TypographyProps,
  ref: Ref<RNText>
) {
  const { colors, fonts } = useTheme();

  const colorStyle = useMemo(() => {
    if (!color) return undefined;
    return colors[color as ThemeColor];
  }, [colors, color]);

  const fontStyle = useMemo(() => {
    if (!font) return undefined;
    return fonts[font];
  }, [fonts, font]);

  return (
    <Text
      ref={ref}
      style={[colorStyle ? { color: colorStyle } : undefined, fontStyle, style]}
      {...rest}
    />
  );
});
