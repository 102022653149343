import {
  AsYouType,
  isSupportedCountry,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js/max";
import {
  isValid,
  parseISO,
  parse,
  format as formatDate,
  addDays,
} from "date-fns";
import * as Yup from "yup";

import { isDayInvalid } from "./dateTime";

import type { isDayInvalidOpts } from "./dateTime";
import type { CountryCode } from "libphonenumber-js/max";

export type PhoneMethodType = (countryCode?: string) => Yup.StringSchema;

export function yupPhone(countryCode: CountryCode = "US") {
  const errMsg = isSupportedCountry(countryCode)
    ? `Must be a valid phone number for region ${countryCode}`
    : `Must be a valid phone number.`;

  return Yup.string().test(
    "phone",
    errMsg,
    (value: string | null | undefined) => {
      if (isAbsent(value)) {
        return true;
      }

      if (!isSupportedCountry(countryCode)) {
        return false;
      }

      const phoneNumber = parsePhoneNumberFromString(value || "", countryCode);

      if (!phoneNumber || !phoneNumber.isValid()) {
        return false;
      }

      return true;
    }
  );
}

export function yupCurrency() {
  return Yup.number()
    .typeError("Must be a valid currency amount")
    .positive("Must be more than zero");
}

export function yupIntlPhone() {
  const errMsg = "Must be a valid phone number for this region";

  return Yup.string().test(
    "phone",
    errMsg,
    function (value: string | null | undefined) {
      const { path, createError } = this;

      if (isAbsent(value)) {
        return true;
      }

      if (!value) {
        return false;
      }

      const asYouType = new AsYouType();

      asYouType.input(value);

      if (asYouType.isValid() && asYouType.isInternational()) {
        const phoneNumber = parsePhoneNumber(value);
        if (!phoneNumber || !phoneNumber.isValid()) {
          return createError({ path, message: errMsg });
        }
      } else {
        return createError({ path, message: errMsg });
      }

      return true;
    }
  );
}

export interface YupDateArgs extends isDayInvalidOpts {
  format?: YupDateFormat;
}
export type YupDateType = (args?: YupDateArgs) => Yup.StringSchema;
export type USDateFormat = "mm/dd/yyyy";
export type ISODateFormat = "yyyy-mm-dd";
export type YupDateFormat = USDateFormat | ISODateFormat;

export const USDateRegex = new RegExp(/([0-9]{2})\/([0-9]{2})\/([0-9]{4})/);
export const ISODateRegex = new RegExp(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);

export function USToISODateString(USDateStr: string): string | undefined {
  const match = USDateStr?.match(USDateRegex);
  if (match && match[3]) {
    return `${match[3]}-${match[1]}-${match[2]}`;
  }

  return undefined;
}

export function isValidDateInput(
  date: string,
  format: YupDateFormat = "mm/dd/yyyy"
) {
  if (date) {
    let matches: RegExpMatchArray | null;
    let month, day, year;
    switch (format) {
      case "yyyy-mm-dd":
        matches = date.match(ISODateRegex);
        if (!matches) return false;
        year = matches[1];
        month = matches[2];
        day = matches[3];
        break;
      default:
        // default is "mm/dd/yyyy"
        matches = date.match(USDateRegex);
        if (!matches) return false;
        month = matches[1];
        day = matches[2];
        year = matches[3];
        break;
    }

    if (isValid(parseISO(`${year}-${month}-${day}`))) {
      return true;
    }
  }
  return false;
}

function isAbsent(value: string | null | undefined): value is undefined | null {
  return value == null;
}

export function yupDate({
  format,
  minDate,
  maxDate,
  includeDates,
  excludeDates,
  filterDate,
}: YupDateArgs = {}) {
  format = format ? format : "mm/dd/yyyy";
  const errMsg = `Must be in format ${format} and a valid date`;
  return Yup.string().test(
    "date",
    errMsg,
    function (value: string | null | undefined) {
      const { path, createError } = this;

      if (isAbsent(value)) {
        return true;
      }

      if (!value) {
        return false;
      }

      if (!value.match(format === "mm/dd/yyyy" ? USDateRegex : ISODateRegex)) {
        return createError({ path, message: `Must be in format ${format}` });
      }

      if (!isValidDateInput(value, format)) {
        return createError({ path, message: `Must be a valid date` });
      }

      const date = parse(
        value,
        (format as string).replace(/m/g, "M"),
        new Date()
      );

      const [isInvalid, err] = isDayInvalid(date, {
        filterDate,
        includeDates,
        excludeDates,
        minDate,
        maxDate,
      });

      if (!isInvalid) return true;

      switch (err) {
        case "filter":
        case "include":
        case "exclude":
          return false;
        case "minMax":
          return createError({
            path,
            message: `Date must be between ${formatDate(
              addDays(minDate as Date, -1),
              (format as string).replace(/m/g, "M")
            )} and ${formatDate(
              addDays(maxDate as Date, 1),
              (format as string).replace(/m/g, "M")
            )}`,
          });
        case "min":
          return createError({
            path,
            message: `Date must be on or after ${formatDate(
              minDate as Date,
              (format as string).replace(/m/g, "M")
            )}`,
          });
        case "max":
          return createError({
            path,
            message: `Date must be on or before ${formatDate(
              maxDate as Date,
              (format as string).replace(/m/g, "M")
            )}`,
          });

        default:
          return true;
      }
    }
  );
}

// @todo when we work on the new components in @smartrent/forms
// type Time = {
//   hours: Yup.AnySchema<number>;
//   minutes: Yup.AnySchema<number>;
// };
// export function yupTimeObject({ is24Hour = false }: { is24Hour?: boolean }) {
//   const message = "Invalid Time";
//   return Yup.object<Time>().test("time", message, function (
//     value:
//       | {
//           hours: number;
//           minutes: number;
//         }
//       | undefined
//       | null
//   ) {
//     const { path, createError } = this;
//     let isError = false;

//     if (!value) {
//       return false;
//     }

//     if (!is24Hour && value.hours <= 0) {
//       isError = true;
//     }

//     if (is24Hour && value.hours < 0) {
//       isError = true;
//     }

//     if (!is24Hour && value.hours > 12) {
//       isError = true;
//     }

//     if (is24Hour && value.hours > 23) {
//       isError = true;
//     }

//     if (value.minutes < 0 || value.minutes > 60) {
//       isError = true;
//     }

//     if (isError) {
//       return createError({ path, message });
//     }

//     return true;
//   });
// }

export type YupTimeType = () => Yup.StringSchema;

export const TimeRegex = new RegExp(/^([0-9]{2}):([0-9]{2}) (AM|PM|am|pm)$/);
export function isValidTimeInput(time: string) {
  const match = time.match(TimeRegex);

  if (match && Number(match[1]) <= 12 && Number(match[2]) <= 59) {
    return true;
  }

  return false;
}

export function yupTime() {
  const errMsg = `Must be in format 01:00 PM and a valid time`;
  return Yup.string().test(
    "time",
    errMsg,
    function (value: string | null | undefined) {
      const { path, createError } = this;

      if (isAbsent(value)) {
        return true;
      }

      if (!value) {
        return false;
      }

      if (!value.match(TimeRegex)) {
        return createError({ path, message: errMsg });
      }

      if (!isValidTimeInput(value)) {
        return createError({ path, message: errMsg });
      }

      return true;
    }
  );
}

export type YupColorType = () => Yup.StringSchema;
export const ColorRegex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
export function yupColor() {
  const errMsg = "Enter a valid hex color";

  return Yup.string().test(
    "color",
    errMsg,
    function (value: string | null | undefined) {
      const { path, createError } = this;

      if (isAbsent(value)) {
        return true;
      }

      if (!value) {
        return false;
      }

      if (!value.match(ColorRegex)) {
        return createError({ path, message: errMsg });
      }

      return true;
    }
  );
}
