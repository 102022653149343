import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Platform, useColorScheme } from "react-native";

import { createTheme, smartrent } from "@smartrent/tokens";

import { GlobalStyles } from "./GlobalStyles";

import type {
  BaseThemeContextType,
  RoundingAlias,
  RoundingGlobal,
  SpacingAlias,
  SpacingGlobal,
  ThemeBuilderType,
  ThemeColor,
  ThemeColorAlias,
  ThemeConfig,
  ThemeContextType,
  ThemeFonts,
  ThemeMode,
  ThemeShadow,
} from "@smartrent/tokens";
import type { ShadowStyle } from "@smartrent/tokens/src/types/shadows";

export const ThemeContext = createContext<ThemeContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const createCustomTheme = <
  CustomColor extends string | undefined = undefined,
  CustomShadow extends string | undefined = undefined,
  CustomRounding extends string | undefined = undefined,
  CustomSpacing extends string | undefined = undefined,
>(
  args: Parameters<typeof createTheme>[0]
) => {
  const theme = createTheme(args);

  function useCustomTheme() {
    return useContext(ThemeContext) as BaseThemeContextType & {
      name: string;
      colors: CustomColor extends string
        ? Record<ThemeColor | ThemeColorAlias | CustomColor, string>
        : Record<ThemeColor | ThemeColorAlias, string>;
      shadows: CustomShadow extends string
        ? Record<ThemeShadow | CustomShadow, ShadowStyle>
        : Record<ThemeShadow, ShadowStyle>;
      fonts: ThemeFonts;
      config: ThemeConfig;
      rounding: CustomRounding extends string
        ? Record<CustomRounding | RoundingGlobal | RoundingAlias, number>
        : Record<RoundingGlobal | RoundingAlias, number>;
      spacing: CustomSpacing extends string
        ? Record<CustomSpacing | SpacingGlobal | SpacingAlias, number>
        : Record<SpacingGlobal | SpacingAlias, number>;
    };
  }

  return {
    theme,
    useCustomTheme,
  };
};

export const ThemeProvider = ({
  theme = smartrent,
  initialMode,
  children,
}: {
  theme?: ThemeBuilderType;
  initialMode?: ThemeMode;
  children: any;
}) => {
  const systemMode = useColorScheme();
  const [userMode, setUserMode] = useState<ThemeMode | undefined>(
    () => initialMode
  );
  const mode = useMemo(() => {
    if (userMode) {
      return userMode;
    }

    if (systemMode) {
      return systemMode;
    }

    return "light";
  }, [systemMode, userMode]);

  const themeBuilder = useRef(theme);
  const [builtTheme, setBuiltTheme] = useState(() => theme(mode, Platform.OS));

  const { isDarkMode, isLightMode } = useMemo(() => {
    return {
      isDarkMode: mode === "dark",
      isLightMode: mode === "light",
    };
  }, [mode]);

  useEffect(() => {
    // on native the initialMode may take a second to change since it is being loaded via an async call
    if (Platform.OS !== "web") {
      setUserMode(initialMode as ThemeMode);
    }
  }, [initialMode]);

  const setTheme = useCallback(
    (theme: ThemeBuilderType) => {
      themeBuilder.current = theme;
      setBuiltTheme(theme(mode, Platform.OS));
    },
    [mode]
  );

  useEffect(() => {
    setTheme(themeBuilder.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to rebuild the theme on mode changes
  }, [mode]);

  return (
    <ThemeContext.Provider
      value={{
        ...builtTheme,
        mode: mode,
        setTheme,
        setMode: setUserMode,
        isDarkMode,
        isLightMode,
      }}
    >
      <GlobalStyles theme={builtTheme} />
      {children}
    </ThemeContext.Provider>
  );
};
export const ThemeConsumer = ThemeContext.Consumer;
