import {
  format,
  addDays,
  startOfMonth,
  eachDayOfInterval,
  getDay as getDayOfWeekIndex,
  setMonth,
  startOfDay,
  isBefore,
  isAfter,
} from "date-fns";

import type { DayOfWeekIndex, MonthIndex } from "@smartrent/types";

/**
 *
 * @deprecated use the @smartrent/forms version of this util
 */
export function getActiveMonth(
  activeMonthIndex?: MonthIndex,
  dateBase = new Date()
) {
  let monthDate: Date;
  if (activeMonthIndex) {
    monthDate = startOfMonth(setMonth(startOfDay(dateBase), activeMonthIndex));
  } else {
    monthDate = startOfMonth(startOfDay(dateBase));
  }
  return monthDate;
}

/**
 *
 * @deprecated use the @smartrent/forms version of this util
 */
export function getCalendarDays({
  activeMonth,
  startOfWeekDayIndex,
}: {
  activeMonth: Date;
  startOfWeekDayIndex: DayOfWeekIndex;
}): Date[] {
  let calendarStartDate: Date | undefined;
  if (getDayOfWeekIndex(activeMonth) > startOfWeekDayIndex) {
    // previous months days should show
    calendarStartDate = addDays(
      activeMonth,
      -1 * (getDayOfWeekIndex(activeMonth) - startOfWeekDayIndex)
    );
  } else {
    // previous months days should not show
    calendarStartDate = activeMonth;
  }

  return eachDayOfInterval({
    start: calendarStartDate,
    end: addDays(calendarStartDate, 41),
  });
}

export interface isDayInvalidOpts {
  filterDate?: (date: Date) => boolean;
  includeDates?: Date[];
  excludeDates?: Date[];
  minDate?: Date;
  maxDate?: Date;
}

/**
 *
 * @deprecated use the @smartrent/forms version of this hook
 */
export function isDayInvalid(
  date: Date,
  { filterDate, includeDates, excludeDates, minDate, maxDate }: isDayInvalidOpts
): [
  boolean,
  "filter" | "include" | "exclude" | "min" | "max" | "minMax" | undefined,
] {
  if (filterDate && !filterDate(date)) {
    return [true, "filter"];
  }

  const formattedDate = format(date, "P");

  if (
    includeDates &&
    !includeDates
      .map((includeDate) => format(includeDate, "P"))
      .includes(formattedDate)
  ) {
    return [true, "include"];
  }

  if (
    excludeDates &&
    excludeDates
      .map((excludeDate) => format(excludeDate, "P"))
      .includes(formattedDate)
  ) {
    return [true, "exclude"];
  }

  if (
    minDate &&
    maxDate &&
    isBefore(startOfDay(maxDate), date) &&
    isAfter(startOfDay(minDate), date)
  ) {
    return [true, "minMax"];
  }

  if (minDate && isAfter(startOfDay(minDate), date)) {
    return [true, "min"];
  }

  if (maxDate && isBefore(startOfDay(maxDate), date)) {
    return [true, "max"];
  }

  return [false, undefined];
}

export interface handleCalendarDatePressOpts {
  date: Date;
  selectedDateStart?: Date;
  selectedDateEnd?: Date;
  isDisabled: boolean;
}

/**
 *
 * @deprecated use the @smartrent/forms version of this hook
 */
export function handleCalendarDatePress({
  date,
  selectedDateStart,
  selectedDateEnd,
  isDisabled,
}: handleCalendarDatePressOpts): Array<Date | undefined> | undefined {
  if (isDisabled) return undefined;

  if (selectedDateStart || selectedDateEnd) {
    if (selectedDateStart && !selectedDateEnd) {
      if (isBefore(date, selectedDateStart)) {
        return [date, selectedDateStart];
      } else {
        return [selectedDateStart, date];
      }
    } else if (selectedDateStart && selectedDateEnd) {
      return [date, undefined];
    }
  } else {
    return [date];
  }

  return undefined;
}

export type DateStringFormat = "yyyy-MM-dd" | "MM/dd/yyyy";

export function dateStringAsYouType(date: string, format: DateStringFormat) {
  let delimiter = "";

  switch (format) {
    case "yyyy-MM-dd":
      delimiter = "-";
      break;
    case "MM/dd/yyyy":
      delimiter = "/";
      break;
    default:
      throw new Error("invalid format");
  }

  const formatGroups = format.split(delimiter).map((str) => str.length);

  const dateDigits = date.replace(/[^0-9]/g, "");
  const dateGroups = formatGroups
    .reduce((acc: string[], length) => {
      const groupIndex = acc.length;
      const prevGroupsLength = acc.slice(0, groupIndex).join("").length;

      return [...acc, dateDigits.substr(prevGroupsLength, length)];
    }, [])
    .filter((str) => str !== "");

  return dateGroups.join(delimiter);
}

export const getTimeObject = (time: string): [number, number] => {
  const match = time.match(/^([0-9]{2}):([0-9]{2})$/);
  if (!match) {
    throw new Error("Invalid Time");
  }
  const [, hour, minute] = match;
  return [Number(hour), Number(minute)];
};

export const getCurrentTime = (date = new Date()) => {
  return `${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

export const getTimeFormatted = (time: string): string => {
  const [hour, minute] = getTimeObject(time);
  const hour12 = hour % 12 || 12;

  let suffix = "AM";

  if (hour >= 12) {
    suffix = "PM";
  }

  return `${hour12.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")} ${suffix}`;
};
