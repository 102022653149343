import { createContext, useContext } from "react";

import type { ReactNode } from "react";
import type { TextProps } from "react-native";

export type TextScalingProps = Pick<
  TextProps,
  "allowFontScaling" | "maxFontSizeMultiplier"
>;

interface TextScalingContextState extends TextScalingProps {}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const TextScalingContext = createContext<TextScalingContextState>({
  allowFontScaling: true,
  maxFontSizeMultiplier: undefined,
});

interface TextScalingConfigProps extends TextScalingContextState {
  children: ReactNode;
}

export function TextScalingConfig({
  allowFontScaling,
  maxFontSizeMultiplier,
  children,
}: TextScalingConfigProps) {
  return (
    <TextScalingContext.Provider
      value={{
        allowFontScaling,
        maxFontSizeMultiplier,
      }}
    >
      {children}
    </TextScalingContext.Provider>
  );
}

export function useTextScaling() {
  return useContext(TextScalingContext);
}
